<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('BANKACCOUNTS.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('BANKACCOUNTS.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card v-if="editMode" >
        <div id="editBankAccountCard" class="vs-con-loading__container">
          <h3 v-if="!newBankAccount">{{$t('BANKACCOUNTS.EDIT.HEADER')}}</h3>
          <h3 v-if="newBankAccount">{{$t('BANKACCOUNTS.NEW.HEADER')}}</h3>
          <div class="default-input d-flex align-items-center">
            <vs-input :label="$t('BANKACCOUNTS.EDIT.LABEL.NAME')"  class="inputx" :placeholder="$t('BANKACCOUNTS.EDIT.LABEL.NAME')"  v-model="selected.name" />
            <vs-input :label="$t('BANKACCOUNTS.EDIT.LABEL.BANKNAME')"  class="inputx ml-3" :placeholder="$t('BANKACCOUNTS.EDIT.LABEL.BANKNAME')"  v-model="selected.bank_name" />
          </div>
          <div class="default-input d-flex align-items-center mt-2">
            <vs-input :label="$t('BANKACCOUNTS.EDIT.LABEL.IBAN')"  class="inputx" :placeholder="$t('BANKACCOUNTS.EDIT.LABEL.IBAN')"  v-model="selected.iban" />
            <vs-input :label="$t('BANKACCOUNTS.EDIT.LABEL.BIC')"  class="inputx ml-3" :placeholder="$t('BANKACCOUNTS.EDIT.LABEL.BIC')"  v-model="selected.bic" />
            <vs-input :label="$t('BANKACCOUNTS.EDIT.LABEL.NAME_OWNER')"  class="inputx ml-3" :placeholder="$t('BANKACCOUNTS.EDIT.LABEL.NAME_OWNER')"  v-model="selected.name_owner" />
          </div>
          <div class="default-input d-flex align-items-center mt-2">
                <vs-radio v-model="selected.accountType" :disabled="!newBankAccount" vs-name="ownertype" :vs-value="GetOwnerTypeUser()" class="mr-3">{{$t('BANKACCOUNTS.NEW.RADIO.USER')}}</vs-radio>
                <vs-radio v-model="selected.accountType" :disabled="!newBankAccount" vs-name="ownertype" :vs-value="GetOwnerTypeCompany()" class="mr-3">{{$t('BANKACCOUNTS.NEW.RADIO.COMPANY')}}</vs-radio>
          </div>          
          <SaveCancel class="mt-3" :SaveAllowed = !bankaccountDataChanged v-on:SaveClicked="Save" v-on:CancelClicked="Cancel"></SaveCancel>   
        </div>
      </vs-card>
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddBankAccount">{{$t('BANKACCOUNTS.ADD')}}</vs-button>
            </div> 
            <div id="bankaccount_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="bankaccounts"
                :noDataText="$t('BANKACCOUNTS.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('BANKACCOUNTS.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">  
                  <vs-th sort-key="accountType">
                  </vs-th>                              
                  <vs-th sort-key="name">
                    {{$t('BANKACCOUNTS.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="bank_name">
                    {{$t('BANKACCOUNTS.TABLE.COL2')}}
                  </vs-th>                  
                  <vs-th sort-key="IBAN">
                    {{$t('BANKACCOUNTS.TABLE.COL3')}}
                  </vs-th>
                  <vs-th sort-key="BIC">
                    {{$t('BANKACCOUNTS.TABLE.COL4')}}
                  </vs-th>        
                  <vs-th sort-key="owner">
                    {{$t('BANKACCOUNTS.TABLE.COL5')}}
                  </vs-th>     
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td>
                      <vs-avatar :icon="OwnerTypeToIconName(data[indextr].accountType)"/>
                    </vs-td>                      
                    <vs-td :data="data[indextr].name">
                      {{data[indextr].name}}
                    </vs-td>      
                    <vs-td :data="data[indextr].bank_name">
                      {{data[indextr].bank_name}}
                    </vs-td>                     
                    <vs-td :data="data[indextr].iban">
                      {{data[indextr].iban}}
                    </vs-td>
                    <vs-td :data="data[indextr].bic">
                      {{data[indextr].bic}}
                    </vs-td>
                    <vs-td :data="data[indextr].name_owner">
                      {{data[indextr].name_owner}}
                    </vs-td>                   

                    <vs-td>
                      <vs-button  @click="EditBankAccount(data[indextr].id)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteBankAccount(data[indextr].id,data[indextr].name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import SaveCancel from './components/SaveCancel';
import helperMethods from '../helper/staticFuncHelper';
import {ownertype} from "../helper/staticFuncHelper";

export default {
  name: "BankAccounts",
  components: {
    SaveCancel
  },
    data: function (){
      return {
      bankaccounts:[],
      selected:[],
      selectedOld:"",
      editMode:false,
      newBankAccount:false
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.bankaccount.bankaccountListUser.data != null)
      {
        data = this.$store.state.bankaccount.bankaccountListUser.data;
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.bankaccount.bankaccountListUser.status) != 'undefined')
        loading = this.$store.state.bankaccount.bankaccountListUser.status.loading;
      return loading;
    },
    bankaccountDataChanged(){
        return JSON.stringify(this.selected) != this.selectedOld;
    }
  },
  watch: {
      getTableData(value) {
          this.bankaccounts = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#bankaccount_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#bankaccount_table'});
        }
      }
    },
  methods: {    
      ...helperMethods,
      LoadData: function ()
      {
          this.$store.dispatch('bankaccount/getBankAccountListForCompanyAndUser', {id:0,forTable:true}); 
      },
      GetOwnerTypeUser(){
        return ownertype.USER;
      },
      GetOwnerTypeCompany(){
        return ownertype.COMPANY;
      },    
      EditBankAccount(id){
        this.newBankAccount = false;
        if(this.$store.state.bankaccount.bankaccountListUser.data != null)
        {
          for(var i = 0; i < this.$store.state.bankaccount.bankaccountListUser.data.length; i++)
          {
            if(this.$store.state.bankaccount.bankaccountListUser.data[i].id==id)
            {
              this.selectedOld = JSON.stringify(this.$store.state.bankaccount.bankaccountListUser.data[i]);
              this.selected = JSON.parse(this.selectedOld);
              this.editMode = true;
              break;
            }
          }
        }
      },
      AddBankAccount(){
        this.selected={id:0,name:"",bank_name:"",iban:"",bic:"",name_owner:"",id_owner:0,accountType: ownertype.USER};
        this.selectedOld = JSON.stringify(this.selected);
        this.editMode = true;
        this.newBankAccount = true;
      },
      Save(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#editBankAccountCard'});

        var name = this.selected.name;
            this.$store.dispatch('bankaccount/saveBankAccount', this.selected)
              .then(savedId => {
                if(savedId !== -1)
                {
                  this.LoadData();
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('BANKACCOUNTS.SUCCESS.SAVE').replace("*1*",name)});
                  this.editMode = false;
                }
                else
                {
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('BANKACCOUNTS.ERROR.SAVE').replace("*1*",name)});
                }
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('BANKACCOUNTS.ERROR.SAVE').replace("*1*",name)+' '+error});
              });        
      },
      Cancel(){
        this.$data.selected = [];
        this.editMode = false;
        this.newBankAccount = false;
      },
      OpenConfirmDeleteBankAccount(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('BANKACCOUNTS.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('bankaccount/deleteBankAccount', parameters.id)
        .then(response => {

          if(response.success === true)
          {
            this.LoadData();
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('BANKACCOUNTS.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('BANKACCOUNTS.WARNING.DELETE').replace("*1*",parameters.name)}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('BANKACCOUNTS.ERROR.DELETE').replace("*1*",error)});  
        });
      }
  }
};

</script>